import React, { useEffect, useState } from "react";
import uid from "uid";
import { Link, navigate } from "@reach/router";
import dayjs from "dayjs";

const DocumentLink = ({ uid, data, createdDate }) => {
  let title = false;
  if (
    data &&
    data.length > 0 &&
    data[0] &&
    data[0].children &&
    data[0].children.length > 0 &&
    data[0].children[0].text
  ) {
    title = data[0].children[0].text;
  }

  const d = dayjs(Date(createdDate)).format("MMM DD, YY");

  return (
    <Link className="document-link" to={`/note/${uid}`}>
      {title ? title : "Untitled document"}

      <span className="date">{d}</span>
    </Link>
  );
};

const Home = ({ user, signOut, db }) => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const getNotes = async () => {
      const notesRef = await db
        .collection(`users`)
        .doc(user.uid)
        .collection("notes")
        .orderBy("lastUpdatedDate", "desc")
        .get();

      if (notesRef.docs) {
        const data = notesRef.docs.map((doc) => doc.data());
        setNotes(data);
      }
    };

    getNotes();
  }, [user, db]);

  const createNote = () => {
    const newUid = uid();
    const d = new Date();
    const data = {
      id: newUid,
      uid: newUid,
      createdDate: JSON.stringify(d),
      lastUpdatedDate: JSON.stringify(d),
    };

    db.collection("users")
      .doc(user.uid)
      .collection("notes")
      .doc(newUid)
      .set(data)
      .then(() => {
        console.log("created new document");
      })
      .catch((err) => {
        console.log(err, "error");
      });

    navigate(`/note/${newUid}`);
  };

  return (
    <div>
      <div className="notes">
        {notes.map((n) => (
          <DocumentLink {...n} key={n.uid} />
        ))}
      </div>

      <aside id="create-new-note" onClick={createNote}>
        <div>+ New Note</div>
      </aside>
    </div>
  );
};

export default Home;
