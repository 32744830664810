import React, { useState, useEffect } from "react";
import { Router, Link } from "@reach/router";
import dayjs from "dayjs";

import withFirebaseAuth from "react-with-firebase-auth";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import firebaseConfig from "./firebase.config";

import Home from "./Home";
import Editor from "./Editor";

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
const db = firebase.firestore();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

const App = (props) => {
  const [time, setTime] = useState("");
  const [stylizedTime, setStylizedTime] = useState("");
  const { user, signOut, signInWithGoogle } = props;

  useEffect(() => {
    let t;
    const startRunning = () => {
      setTime(new Date());
    };
    t = setInterval(startRunning, 1000);
    startRunning();

    return () => {
      clearInterval(t);
    };
  }, []);

  useEffect(() => {
    if (time) {
      const d = dayjs(time).format("MMMM D, YYYY – hh:mma");
      setStylizedTime(d);
    }
  }, [time]);

  return (
    <div className="App">
      <Link className="timer" to="/">
        {stylizedTime}
      </Link>

      {user ? (
        <Router>
          <Home db={db} user={user} signOut={signOut} path="/" />
          <Editor time={time} db={db} user={user} path="/note/:note" />
        </Router>
      ) : (
        <div className="sign-in" onClick={signInWithGoogle}>
          Sign in with Google
        </div>
      )}
    </div>
  );
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
