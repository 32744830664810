import React, { useEffect, useMemo, useState } from "react";
import { createEditor } from "slate";

// Import the Slate components and React plugin.
import { Slate, withReact } from "slate-react";
import {
  BlockquotePlugin,
  BoldPlugin,
  CodeBlockPlugin,
  CodePlugin,
  EditablePlugins,
  ExitBreakPlugin,
  HeadingPlugin,
  ImagePlugin,
  ItalicPlugin,
  ListPlugin,
  LinkPlugin,
  ParagraphPlugin,
  pipe,
  ResetBlockTypePlugin,
  TodoListPlugin,
  SoftBreakPlugin,
  StrikethroughPlugin,
  withAutoformat,
  withList,
  withLink,
  withToggleType,
  withImageUpload,
  withInlineVoid,
} from "@udecode/slate-plugins";

import {
  options,
  headingTypes,
  optionsResetBlockTypes,
} from "./initialValues.ts";
import { autoformatRules } from "./autoformatRules.ts";

const plugins = [
  ParagraphPlugin(options),
  BoldPlugin(),
  ItalicPlugin(),
  ImagePlugin(options),
  CodePlugin(),
  StrikethroughPlugin(),
  BlockquotePlugin(options),
  ListPlugin(options),
  LinkPlugin(options),
  TodoListPlugin(options),
  HeadingPlugin(options),
  CodeBlockPlugin(options),
  ResetBlockTypePlugin(optionsResetBlockTypes),
  SoftBreakPlugin({
    rules: [
      { hotkey: "shift+enter" },
      {
        hotkey: "enter",
        query: {
          allow: [options.code_block.type, options.blockquote.type],
        },
      },
    ],
  }),
  ExitBreakPlugin({
    rules: [
      {
        hotkey: "mod+enter",
      },
      {
        hotkey: "mod+shift+enter",
        before: true,
      },
      {
        hotkey: "enter",
        query: {
          start: true,
          end: true,
          allow: headingTypes,
        },
      },
    ],
  }),
];

const withPlugins = [
  withReact,
  withList(options),
  withLink(options),
  withImageUpload(),
  withToggleType({ defaultType: options.p.type }),
  withAutoformat({
    rules: autoformatRules,
  }),
  withInlineVoid({ plugins }),
];

const Editor = ({ user, db, note, time }) => {
  const editor = useMemo(() => pipe(createEditor(), ...withPlugins), []);
  // Add the initial value when setting up our state.
  const [dbWritter, setDbWritter] = useState(null);
  const [value, setValue] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  useEffect(() => {
    const getData = async () => {
      const noteRef = await db
        .collection(`users`)
        .doc(user.uid)
        .collection("notes")
        .doc(note);
      const noteReq = await noteRef.get();

      if (noteReq.exists) {
        const data = noteReq.data();
        if (data.data) {
          // setValue(JSON.parse(data));
          setValue(data.data);
        }
      }
    };

    getData();

    setDbWritter(
      db.collection(`users`).doc(user.uid).collection("notes").doc(note)
    );
  }, [note, db, user.uid]);

  useEffect(() => {
    const writeToDb = async () => {
      if (dbWritter) {
        await dbWritter.update({
          data: value,
          lastUpdatedDate: time,
        });
      }
    };

    writeToDb();
  }, [value]);

  return (
    <div className="editor">
      <Slate
        editor={editor}
        value={value}
        onChange={(newValue) => setValue(newValue)}
      >
        <EditablePlugins
          autoFocus
          plugins={plugins}
          placeholder="Enter some text"
        />
      </Slate>
    </div>
  );
};

export default Editor;
